<!--
 * @Author: cyy
 * @Date: 2022-01-24 11:10:26
 * @LastEditTime: 2022-10-31 15:30:41
 * @LastEditors: cyy
 * @Description: 话术本
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\history.vue
-->
<template>
  <div class="messageTemplate">
    <div class="template-copntain">
      <div class="template-detail">
        <pagination2
          :option="post2"
          url="/chatMessage/chatMsgList"
          ref="childDialogBox"
          @complete="complete"
        >
          <template v-slot:default="{}">
            <div class="timepicker">
              <el-button
                type="primary"
                size="medium"
                @click="showAddTemplate = true"
              >
                {{ type==3?'新建智能脚本':'创建话术本' }}
              </el-button>
              <div>
                <span class="tips" v-if="type==3">类型</span>
                <el-select
                  v-if="type==3"
                  v-model="post.msg_type"
                  class="mr10"
                  size="medium"
                  style="width: 140px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>

                <el-input
                  clearable
                  @keyup.enter.native="toSearch"
                  v-model="post.search"
                  size="medium"
                  placeholder="输入名称搜索"
                  style="width:188px"
                  class=" ml10 mr20"
                ></el-input>

                <el-button type="primary" size="medium" @click="toSearch">
                  搜索
                </el-button>
              </div>
            </div>

            <el-table
              :fit="true"
              @selection-change="selectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              @sort-change="sortChange"
              :data="tempplateContentList"
            >
              <el-table-column label="模板ID" min-width="50" v-if="type==3">
                <template slot-scope="{ row }">
                  <div class="people-contain">
                    <div class="msg_id" :title="row.msg_id">
                      {{ row.msg_id }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="名称" min-width="200">
                <template slot-scope="{ row }">
                  <div class="people-contain">
                    <div class="name" :title="row.name">
                      {{ row.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="视频时长" min-width="100" v-if="type==3">
                <template slot-scope="{ row }">
                  <div class="people-contain">
                    <div class="duration" :title="row.duration">
                      {{ row.duration | formatTimeLength }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="评论条数" min-width="100" v-if="type==3">
                <template slot-scope="{ row }">
                  <div class="select-send-time">
                    {{ row.num || '-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="来源" min-width="100" v-if="type==3">
                <template slot-scope="{ row }">
                  <span style="color: #E054EC;" v-if="row. msg_type==1">直播生成</span>
                  <span style="color: #2CCF43;" v-else>手动增加</span>
                </template>
              </el-table-column>
              <el-table-column label="类型" v-if="type!=3">
                <template slot-scope="{ row }">
                  {{ row.type | gettype }}
                </template>
              </el-table-column>
              <el-table-column label="评论条数" min-width="100" v-if="type!=3">
                <template slot-scope="{ row }">
                  <div class="select-send-time">
                    {{ row.num || '-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="创建人" min-width="120">
                <template slot-scope="{ row }">
                  {{ row.uname }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" sortable="custom" prop="c_time" min-width="120">
                <template slot-scope="{ row }">
                  <span v-if="row.c_time">
                    {{ row.c_time | formatTimeStamp }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="right" prop="address" min-width="100">
                <template slot-scope="{ row }">
                  <div class="caozuo">
                    <el-button
                      slot="reference"
                      type="text"
                      @click="toDetail(row)"
                    >
                      详情
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button slot="reference" type="text" @click="edit(row)">
                      编辑
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button
                      slot="reference"
                      type="text"
                      @click="removeTemplate(row)"
                    >
                      删除
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="footer mt20" slot="footer">
              <span class="total">已选择{{ messageSelection.length }}条</span>

              <el-button
                slot="reference"
                style="width: 150px"
                class="ml20"
                size="medium"
                :disabled="messageSelection.length == 0"
                @click="delTemplateContent"
              >
                批量删除
              </el-button>
            </div> -->
          </template>
        </pagination2>
      </div>
    </div>

    <!-- 新建模板 -->
    <el-dialog
      :title="msg_id ? '编辑话术本' : '创建话术本'"
      :close-on-click-modal="false"
      :visible.sync="showAddTemplate"
      width="800px"
      @close="cancelshowAddTemplate"
    >
      <el-form label-width="120px" label-position="left">
        <el-form-item label="智能脚本名称" required v-if="type==3"></el-form-item>
        <el-form-item label="话术本名称" v-else>
          <el-input
            type="text"
            placeholder="请输入模板名称"
            v-model="creatlist.name"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <el-input
        v-if="type==3"
        type="text"
        placeholder="请输入智能脚本名称"
        v-model="creatlist.name"
        maxlength="50"
        show-word-limit
      ></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelshowAddTemplate">取 消</el-button>
        <el-button type="primary" @click="submitShowAddTemplate">
          {{ msg_id ? '保存' : '保存并进入详情' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'messageTemplate',
  props:{
    type:Number,
    msg_type:Number,
  },
  data() {
    return {
      creatlist: {
        name: '',
        // 1:自动发言,2:模拟对话,3:聊天记录
        type: this.type,
      },

      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '直播生成',
        },
        {
          value: 2,
          label: '手动增加',
        },
      ],
      showAddTemplate: false,

      post: {
        search: '',
        sort: '',
        msg_type:this.msg_type,
      },

      post2: {
        // 1:自动发言,2:模拟对话,3:聊天记录
        type: this.type,
        sort: '',
      },

      // 编辑时所选数据的id
      msg_id: '',

      tempplateContentList: [],

      messageSelection: [],
    }
  },

  watch: {},

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '自动发言 '
          break
        case 2:
          text = '模拟对话'
          break
        case 3:
          text = '聊天记录'
          break
      }
      return text
    },
  },
  methods: {
    // 详情
    toDetail(val) {
      this.$router.push({
        path: '/messageTemplate/messageDetail',
        query: { msg_id: val.msg_id },
      })
    },

    toSearch() {
      this.post2 = _.assign({}, this.post)
    },

    // 列表排序
    sortChange(val) {
      this.post2.sort = !val.order ? '' : val.order === 'ascending' ? '1' : '2'
    },

    // 删除
    async removeTemplate({ msg_id }) {
      let delstr = ''
      switch (this.type) {
        case 3:
          delstr = '是否确定删除智能模板？'
          break;
        default:
          delstr = '是否确定删除已选话术本？'
          break;
      }
      await this.$confirm(delstr, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      this.$root.$http({
        url: '/chatMessage/delChatMsg',
        data: {
          msg_id,
        },
        callback: () => {
          this.$root.prompt({
            msg: '删除成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        },
      })
    },

    complete(val) {
      this.tempplateContentList = val.data
      this.count = val.count
      this.videoInfo = {
        video_name: val.video_name,
        video_url: val.video_url,
      }
    },

    // 取消创建模板
    cancelshowAddTemplate() {
      this.showAddTemplate = false
      this.creatlist = {
        name: '',
        type: '1',
      }
      this.msg_id = ''
    },

    // 编辑话术本
    edit(val) {
      this.msg_id = val.msg_id
      this.creatlist = _.assign({}, { type: val.type, name: val.name })
      this.showAddTemplate = true
    },

    //新建/编辑话术本  保存
    async submitShowAddTemplate() {
      if (!this.creatlist.name.trim()) {
        this.$root.prompt({
          msg: '请输入话术本名称',
        })
        return
      }

      const { data } = await this.$http({
        url: this.msg_id
          ? '/chatMessage/editChatMsg'
          : '/chatMessage/addChatMsg',
        data: _.assign({}, this.creatlist, {
          msg_id: this.msg_id ? this.msg_id : undefined,
        }),
      })
      if (!this.msg_id) {
        this.$router.push({
          path: '/messageTemplate/messageDetail',
          query: { msg_id: data },
        })
      } else {
        this.$root.prompt({
          msg: '操作成功!',
          type: 'success',
        })
        this.$refs.childDialogBox.reset()
        this.cancelshowAddTemplate()
      }
    },

    selectionChange(value) {
      this.messageSelection = value
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: inherit;
}
.el-divider {
  background: rgba(27, 157, 151, 1);
}
.tips {
  margin-right: 10px;
  font-size: 14px;
  color: #333333;
}

img {
  height: 100%;
  width: 100%;
}
.messageTemplate {
  min-width: 935px;
  padding: 20px;
  background-color: #fff;
  margin-top: -20px;

  .timepicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
.template-copntain {
  display: flex;

  .template-detail {
    overflow: hidden;
    flex: 1;
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .people-contain {
      display: flex;
      align-items: center;
      .cover-img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 12px;
        margin-right: 10px;
        background: #c5c5c5;
      }
      .name {
        flex: 1;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    ::v-deep .select-send-time {
      .el-date-editor {
        width: 128px;
      }
      .el-input__inner {
        border: 0;
      }
    }
    ::-webkit-scrollbar {
      width: 2px;
      height: 8px;
      background-color: #fff;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
      background-color: #fff;
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }
  }
  .footer {
    margin-top: 20px;
    .total {
      font-size: 14px;
      color: #333333;
    }
  }
}

::v-deep .caozuo {
  font-size: 12px;
  color: #0aa29b;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .sp_drive {
    margin: 0 5px;
  }
  .el-button {
    font-size: 12px;
    color: #0aa29b;
    &.is-disabled {
      color: #c0c4cc;
    }
  }
}
</style>
