<!--
 * @Author: cyy
 * @Date: 2022-01-24 11:10:26
 * @LastEditTime: 2022-10-26 15:25:35
 * @LastEditors: cyy
 * @Description: 消息模板
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\main.vue
 eacher.dingdingkaike\src\views\LiveBroadcastManagement\messageTemplate\main.vue
-->
<template>
  <div id="messageTemplate">
    <el-tabs v-model="activeName">
      <el-tab-pane label="智能脚本" name="3">
        <history v-if="activeName == 3" :type="3" :msg_type="0"></history>
      </el-tab-pane>
      <el-tab-pane label="自动发言" name="1">
        <history v-if="activeName == 1" :type="1"></history>
      </el-tab-pane>
      <el-tab-pane label="模拟对话" name="2">
        <history v-if="activeName == 2" :type="2"></history>
      </el-tab-pane>
      <!-- <el-tab-pane label="话术本库" name="2">
        <ordinary v-if="activeName == 2"></ordinary>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="话术本库" name="3">
        <ordinary v-if="activeName == 2"></ordinary>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
// import ordinary from './ordinary'
import bg from '@/mixin/background'
import history from './history'
export default {
  name: 'messageTemplate',

  mixins: [bg],

  components: {
    history,
    // ordinary,
  },

  data() {
    return {
      activeName: '3',
    }
  },
}
</script>

<style lang="scss" scoped>
#messageTemplate {
  margin: 20px;
  background-color: #fff;
  ::v-deep .el-tabs__nav-wrap:after {
    height: 1px;
  }
  ::v-deep .el-tabs__nav-scroll {
    padding: 0 20px;
  }
}
</style>
